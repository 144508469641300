import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

import { Layout, AllSections } from '#components'

function Page({ pageContext }) {
  const sections = pageContext.builder.sections || []

  useEffect(() => {
    if (pageContext.uri === `/register-to-bid/`) {
      navigate(`/bidder-registration/`)
      // window.location.href = `https://admin.auctionhouselondon.co.uk/register-to-bid/`
    }

    if (pageContext.uri === `/why-choose-us/`) {
      navigate(`/choose-us/`);
      // window.location.href = `https://admin.auctionhouselondon.co.uk/register-to-bid/`
    }

    if (pageContext.uri === `/online-auction/`) {
      navigate(`/timed-auctions/`)
      // window.location.href = `https://admin.auctionhouselondon.co.uk/register-to-bid/`
    }

    if (pageContext.uri === `/bidder-registration-30th-march-auction/`) {
      navigate(`/bidder-registration/`)
      // window.location.href = `https://admin.auctionhouselondon.co.uk/register-to-bid/`
    }
  }, [pageContext.uri])

  return (
    <Layout title={pageContext.title}>
      <div
        className={`${
          pageContext.uri === `/register-to-bid/` ? `opacity-0` : ``
        }`}
      >
        {sections.map((section, index) => (
          <AllSections key={index} sectionData={section} />
        ))}
      </div>
    </Layout>
  )
}

Page.propTypes = {
  pageContext: PropTypes.object,
}

export default Page
